.Navbar {
  padding: 0px 25px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link {
  color: #000 !important;
  text-decoration: none !important;
}
.link.active {
  color: #36ba24 !important;
}
.link:hover {
  color: #36ba24 !important;
}

.Navbar > .nav-items > a {
  color: #000 !important;
  font-size: 18px;
  text-decoration: none;
  margin: 15px;
  font-weight: 500;
  padding: 10px 0;
  position: relative;
  opacity: 0.8;
}

.Navbar > .nav-items > a:hover {
  color: #36ba24 !important;
}

.Navbar > .nav-items > a:hover {
  opacity: 1;
}

.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background: #36ba24;
  transition: all 0.45s;
}

.Navbar > .nav-items > a:hover::before {
  width: 100%;
}

.Navbar > .nav-toggle {
  display: none;
}
.Navbar > .nav-items > a.active{
  color: #36ba24;
}

@media (max-width: 700px) {
  .Navbar > .nav-items {
    position: absolute;
    top: 75px;
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    /* background:#402312; */
    background-color: #C13F28;
    /* color: white; */
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(-100%);
    transition: all 0.45s;
    z-index: 999;
  }
  .Navbar > .nav-items > a {
    padding-left: 30px;
    margin: 12px;
font-weight: 700;
    font-size: 18px;
    /* color: #ffffff !important; */
  }
  .Navbar > .nav-items > a::before {
    background-color: transparent;
  }

  .Navbar > .nav-items.open {
    transform: translateX(0);
  }
  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #000;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: #000;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }
  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }
  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
@media screen and (min-width: 820px) and (min-height: 1180px) {
  .nav-items {
    display: flex;
  }
}
@media screen and (min-width: 768px) and (min-height: 1024px) {
  .nav-items {
    display: flex;
  }
  .logo{
    /* height: 100%; */
    width: 150px !important;
  }
}

.logo{
  height: 100%;
  width: 210px;
}
.home{
  position: sticky;
  top: 0;
  z-index: 999;
  /* background-color: #ffffff; */
}
.banner {
  background-image: url("../../assests/images/banner//bannerhome.webp");
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-overlay {
  background-color: #00000050;
  height: 100%;
  color: white;
  display: grid;
  place-items: start;
  align-items: start;
}
.banner_desc {
  font-size: 45px;
}
.heading {
  font-size: 35px;
  text-align: left;
  margin-left: 4px;
}
.ina {
  font-size: 16px;
  margin-left: 7px !important;
}
.butto {
  margin-left: 7px !important;
}
.farmm {
  font-size: 32px;
  font-weight: 700;
}
.backbone {
  font-size: 15px;
  font-weight: bold;
}
.farmmer {
  font-size: 15px;
}
.our {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.ind {
  font-size: 16px;
  font-weight: 500;
}
.footertext {
  text-align: left;
  display: inline;
  position: relative;
}
.footertext::after {
  content: "";
  width: 0.1px;
  display: block;
  background-color: black;
  position: absolute;
  right: 0;
  /* left: 0px !important; */
  top: 52%;
  height: 80%;
  transform: translateY(-50%);
}
.image_grid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image_hover:hover {
  transform: scale(1.2);
  transition: 0.5s ease-in-out;
}
.image_hover {
  transition: 0.5s ease all;
}
.background_farmer__angel {
  background-color: #eee;
}
.benefits_header {
  border: 1px solid #eee;
}
.list::before {
  content: "-";
  margin-right: 5px;
}
.upper_div {
  position: relative;
  overflow: hidden;
}
.process_hover:hover {
  scale: 1.2;
}
.process_hover {
  transition: 1s ease all;
}

.benefits_farmer {
  background-image: url("../../assests/images/banner/field.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 90vh;
}
.bg-benefits_overlay {
  background-color: #0000004b;
  height: 100%;
  display: grid;
  place-items: center;
  align-items: center;
  color: white;
}
.farmer {
  border: 2px dashed #36ba24;
  border-radius: 10px;
}
.angel_farmer {
  border: 2px dashed #36ba24;
  border-radius: 10px;
}
.emp {
  color: #36ba24;
}
.empp {
  color: #36ba24;
}
.benefits_list::before {
  margin-right: 5px;
  /* content: "🍀"; */
  /* content: url(../../assests/images/background/PointerIcon.png) !important; */
  /* transform: scale(0.1); */
  /* height: 50px;
  width: 40px; */
}
.height_farmer {
  height: 400px;
}
.bg-grey {
  background-color: #eff2f5;
}
.bg_contact {
  background-image: url("../../assests/images/banner/crop_wheat.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 20%;
}
.contact_input:focus {
  outline: none;
}
.message_input {
  background-color: #eff2f5;
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
}
.footer_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 1px solid #36ba24;
  border-radius: 50%;
  font-size: 20px;
  background-color: #36ba24;
  color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease all;
}
.footer_icons:hover {
  color: #36ba24;
  background-color: #ffffff;
  transform: scale(1.1);
}
.accordion_head {
  border-bottom: 2px solid #36ba24 !important;
  border: none;
  width: 100%;
  text-align: left;
  padding: 8px;
  background-color: #fff;
  /* background: #36ba24; */
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion_body {
  padding: 2px;
  color: grey;
  font-size: 13px;
  display: flex;
  margin-left: 5px;
}
.inds {
  font-size: 18px;
  font-weight: 700;
}
.india {
  margin-left: 17px !important;
}

.years {
  /* margin-left: 71px; */
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
}

.term {
  font-size: 25px;
  color: #ffffff;
  font-weight: 700;
  padding: 2px 8px;
  text-align: center;
  justify-content: center;
  text-align: center;
}
.short {
  font-size: 12px;
  text-align: start;
  padding: 3px 15px;

}
.crop{
color: #ffffff;
font-size: 20px;
}

.termbg {
  /* background-color: #0000; */
  display: grid;
  place-items: center;
  /* padding: 5px; */
}
.termbgov { 
  background-color: #402312;
    width: 100%;
  height: 100%;
  align-items: start;
}
.long{
  height: 72px;
}
.flip-card {
  /* background-color: transparent; */
  background-color: #402312;
  width: 230px;
  height: 250px;
  perspective: 1000px;
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.flip-card:hover .inner {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front {
  /* background-image: url("../../assests/images/adoptionplans.webp"); */
  /* background-color: #0000; */
  width: 100%;
  height: 100%;
  align-items: start;
}


.back {
  background-color: #228515;
  transform: rotateY(180deg);
}


.minus{
  color: #F59120;
}
@media screen and (max-width: 600px) {
  .height_farmer {
    height: 100%;
  }
  .farmer {
    margin-bottom: 10px;
  }
  .banner {
    height: 40vh;
  }
  .bg-overlay h6 {
    font-size: 20px;
  }
}

@media screen and (min-width: 601px) and (max-width: 991px) {
  .height_farmer {
    height: 520px;
  }
  .banner {
    height: 30vh;
  }
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 20px;
    text-align: left;
    margin-left: 4px;
    margin-top: -165px;
  }
  .ina {
    font-size: 15px !important;
    margin-left: 7px !important;
  }
  .butto {
    margin-left: 7px ;
  }
  .whywork {
    margin-top: 45px;
  }

  .farmm {
    font-size: 20px !important;
    margin-top: 14px !important ;
  }
  .paar {
    font-size: 12px;
    margin-top: -12px;
  }
  .but {
    height: 43px !important;
    font-size: 12px;
  }
  .picturrr {
    margin-top: 22px;
  }
  .ado {
    margin-top: 22px;
    font-size: 18px;
  }
  .emp {
    font-size: 15px;
    margin-top: -82px !important;
  }
  .ind {
    font-size: 13px;
  }
  .longg {
    font-size: 15px;
  }
  .org {
    margin-bottom: 15px;
  }
  .styles-module_whatsappButton__tVits {
    margin-left: 62px;
  }
  .india {
    font-size: 14px;
  }
  .backfara{
    margin-top: -88px 
    !important; 
  }
}

@media screen and (min-width: 991px) and (max-width: 1030px) {
  .banner {
    height: 25vh;
  }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .banner {
    height: 70vh !important;
  }
}

@media screen and (min-width: 1280px) and (min-height: 800px) {
  .banner {
    height: 70vh;
    
  }
}

@media screen and (min-width: 1366px) and (min-height: 1024px) {
  .banner {
    height: 50vh;
  }
}
@media screen and (min-width: 820px) and (min-height: 1180px) {
  .heading {
    font-size: 20px;
    text-align: left;
    margin-left: 4px;
    margin-top: -105px;
  }
  .ina {
    font-size: 15px !important;
    margin-left: 7px !important;
  }
  .butto {
    margin-left: 7px ;
  }

}

@media screen and  (min-width: 1024px) and (min-height:1366px){
  .banner {
    height: 30vh;
  }

}

.f-20 {
  font-size: 20px;
}
.text-primary {
  color: #36ba24 !important;
}
.f-30 {
  font-size: 30px;
}
.text-justify {
  text-align: justify;
}
.text-grey {
  color: grey;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.f-12{
font-size: 12px;
}

.styles-module_whatsappButton__tVits {
bottom:9rem !important;
}
.styles-module_chatBody__VBxQH {
  padding: 8px 8px; 
}
.styles-module_chatBody__VBxQH .styles-module_messageBody__9-hwo {
  font-size: 12px !important;
  line-height: 15px;
  margin-top: 1px;
  padding: 2px 0px;
  color: #111111;
  white-space: pre-wrap;
}
.floatwhatsapp{
  
}

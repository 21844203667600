.pro{
    background-image: url("../../assests/images/farm_field.jpg");
    
    /* background-attachment: fixed; */
  }
  .bg-ACF {
    background-color: rgba(0, 0, 0, 0.203);
    height: 100%;
    color: white;
    display: grid;
    place-items: center;
    align-items: center;
  }
.playstore{

  align-items: center;
  justify-content: center;
}
.resss{
  margin-bottom: 19px !important;
}
.anchor{
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.83);
}
.anchor:hover{
  color: black;
}

.playbutt{
height: 30px;
}
.geet{
  font-size: 12px;
  
}
  
@media screen and (min-width: 991px) and (max-width: 1030px) {
  .pro{height: 25vh;}
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
.pro{
  height: 50vh;
}
}
@media screen and  (min-width: 1024px) and (min-height:1366px){}

@media screen and (min-width: 1280px) and (min-height: 800px) {
  .bannerss{
    height: 55vh !important;
  }}
.footer {
  background-color: #1f4e3d;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 98% !important;
  /* background-image: url("../../assests/images/banner/field.webp");
  /* background-attachment: fixed;  */
}
.bg-footer {
  /* background-color: #22222239; */
height: 283px;
}

.footerlogo{
  height: 80px;
  width: 60%;
  padding-left:20px ;
  align-items: center;
  justify-content: center;
  display: flex;
}
.parag {
  text-align: justify;
padding: 0px 18px;
font-size: 15px;
font-weight: 400;  
}
.ourapp{
  font-size: 15px;
  font-weight: 400;
  text-align: start;
}
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #36ba24;
  background-color: #36ba24;
  color: #ffffff ;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 2px 2px #eee;
  transition: 0.5s ease all;
  cursor: pointer;
}

.icons:hover {
  scale: 1.1;
  background-color: #ffffff;
  color: #36ba24;
}
.links_footer {
  color: #ffffff;
  font-weight: 500;
  text-decoration: none;
}
.links_footer:hover {
  color: #ffffff;
}
.footer_links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__links {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.playgoo{
  font-size: 16px !important;
  text-align:left !important;
  margin-left: 8px;
  color: black;
  font-weight: 500;
}

.getin{
  text-align: center;
  margin-left: -22px;
  font-size: 12px;
  color: black;
  font-weight: 500;
}
.playgo{
  font-size: 16px !important;
  text-align:left !important;
  margin-left: 8px;
  color: #ffffff;
  font-weight: 500;
}

.geti{
  text-align: center;
  margin-left: -22px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 500;
}

.playbut{
height: 30px;
}
.hover{
  /* margin-left: 89px;
  margin-top: -10px; */
}
@media screen and (max-width: 600px) {
  .footer__links {
    font-size: 9px !important;
  }
}

@media screen and (max-width: 768px) {
  .googlebut{
    height: 38px !important;
    font-size: 12px !important ;
  }
  .styles-module_whatsappButton__tVits{
    margin-left: 42px;
  } 
  .imp {
    margin-top: -37px !important;
    margin-left: -105px;
    padding-top: 3px;
  }
  .pull{
    margin-top: -28px !important;
    margin-left: -16px;
  
  }
  .bg-footer {
    height: 100%;
  }
}

@media screen and (min-width: 991px) and (max-width: 1030px) {
  .footer{height: 25vh;}
}

.imp {
  color: #36ba24;
  display: grid !important;
  place-items: center;
  font-weight: 400; 
  position: relative;
}
.product:hover {
    /* box-shadow: 0px 0px 1px 1px #11111165; */
    scale: 1.1;
  }
  .product {
    transition: 0.5s ease all;
    background-color: #ffffff;
    width: 95%;
    height: 315px;
    margin-left: 12px;
    cursor: pointer;
  }

  .img{
    width: 100%;
    height: 39vh;
  }

  .button{
    align-items: center;
  }
  .benefits_list::before {
    content: "🌿";
    margin-right: 5px;
  }
  /* .heaad{
  text-align: center;
  } */

  @media screen and (min-width: 601px) and (max-width: 991px) {
    .product {
      height: 325px;
    }
  }
  
@media screen and (min-width: 991px) and (max-width: 1030px) {
  .bbb{ height: 1%;}
  .img{height: 10%;}
  .heaad{font-size: 18px;}
}
/* @media screen and (min-width: 1280px) and (min-height: 800px) {
  .img{
height: 28vh;
  }
} */
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .img{
height: 26vh;
  }
}
@media screen and (min-width: 1366px) and (min-height: 1024px) {
  .img{
    height: 26vh;
  }
}

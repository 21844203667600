.feature_box:hover {
  box-shadow: 0px 0px 1px 1px #11111165;
  scale: 1.1;
}
.feature_box {
  transition: 0.5s ease all;
  background-color: #ffffff;
  /* width: 300px; */
  height: 250px;
}
.feature_box p {
  font-size: 14px;
}
@media screen and (min-width: 601px) and (max-width: 991px) {
  .feature_box {
    height: 325px;
  }
}

@media screen and (max-width: 768px) {
  .bbb{height: 10%;
  margin-top: 32px;}
}

